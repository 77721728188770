<template>
    <div v-if="formType != 'progress-tracking' && formType != 'habit-tracking'">
        <!-- <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="tab_slider topTabs">
            <swiper-slide><span @click="form.tab = 'header'" :class="{active: form.tab === 'header'}">Header</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'cover'" :class="{active: form.tab === 'cover'}">Cover</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'bio'" :class="{active: form.tab === 'bio'}">Bio</span></swiper-slide>
            <swiper-slide><span v-if="formType == 'playbook'" @click="form.tab = 'action'" :class="{active: form.tab === 'action'}">Action Buttons</span></swiper-slide>
            <swiper-slide><span v-if="formType == 'page'" @click="form.tab = 'button'" :class="{active: form.tab === 'button'}">Button</span></swiper-slide>
            <swiper-slide><span v-if="formType == 'playbook' || formType == 'form'" @click="form.tab = 'progress'" :class="{active: form.tab === 'progress'}">Progress</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'footer'" :class="{active: form.tab === 'footer'}">Footer</span></swiper-slide>
        </swiper> -->
        <ul class="tab_sec header">
            <li @click="form.tab = 'header'" :class="{active: form.tab === 'header'}">Header</li>
            <li @click="form.tab = 'cover'" :class="{active: form.tab === 'cover'}">Cover</li>
            <li @click="form.tab = 'bio'" :class="{active: form.tab === 'bio'}">Bio</li>
            <li v-if="formType == 'playbook'" @click="form.tab = 'action'" :class="{active: form.tab === 'action'}">Action Buttons</li>
            <li v-if="formType == 'page'" @click="form.tab = 'button'" :class="{active: form.tab === 'button'}">Button</li>
            <li v-if="formType == 'playbook' || formType == 'form'" @click="form.tab = 'progress'" :class="{active: form.tab === 'progress'}">Progress</li>
            <li @click="form.tab = 'footer'" :class="{active: form.tab === 'footer'}">Footer</li>
        </ul>
        <div class="content_wpr" v-if="form.tab === 'header'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Header Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Header</h5>
                            <label :for="`display-header-${uniqueId}`" class="switch_option capsule_btn border-0 ">
                                <input type="checkbox" :id="`display-header-${uniqueId}`" value="url" v-model="form.display_header" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="mt-2" v-if="form.display_header">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Company Branding</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`company_branding_${formType}`] }">
                                        <Field autocomplete="off" type="text" :name="`company_branding_${formType}`" v-model="form.company_branding" label="company branding" placeholder="ex: Synergy Fitness to Synergy" />
                                    </div>
                                    <ErrorMessage :name="`company_branding_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Header Background Color</label>
                                    <Field autocomplete="off" name="header_bgcolor" v-model="form.header_bgcolor" type="text" label="button text color">
                                        <color-picker v-model="form.header_bgcolor" :classes="{ 'has-error': errors.header_bgcolor }" />
                                    </Field>
                                    <ErrorMessage name="header_bgcolor" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Header Text Color</label>
                                    <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="button text color">
                                        <color-picker v-model="form.header_textcolor" :classes="{ 'has-error': errors.header_textcolor }" />
                                    </Field>
                                    <ErrorMessage name="header_textcolor" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-if="form.display_header">
                <div class="section_header">
                    <h2>Logo Settings</h2>
                </div>
                <div class="color_container mt-2">
                    <ul class="tab_sec bg-transparent m-0 mb-5 pt-2">
                        <li @click="form.logoSetting = 'header'" :class="{active: form.logoSetting === 'header'}">Header Logo</li>
                        <li @click="form.logoSetting = 'author'" :class="{active: form.logoSetting === 'author'}">Author Logo</li>
                    </ul>
                    <div v-if="form.logoSetting === 'header'">
                        <image-library v-model="form.company_logo" image-type="public-logo"/>
                    </div>
                    <div v-if="form.logoSetting === 'author'">
                        <image-library v-model="form.author" image-type="coach-logo"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'cover'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Cover Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="capsule_elm">
                        <h5>Display Cover</h5>
                        <label :for="`diaplay-cover-${uniqueId}`" class="switch_option capsule_btn" @click="handleALertPrompt()">
                            <input type="checkbox" :id="`diaplay-cover-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.display_cover" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="cover_type" v-if="form.display_cover">
                        <ul class="type_list">
                            <li>
                                <label :for="`clr_text-${uniqueId}`">
                                    <input type="radio" :id="`clr_text-${uniqueId}`" :value="1" v-model="form.cover_type" hidden>
                                    <img src="@/assets/images/background.svg" alt="">
                                    <h5>Color + Text</h5>
                                </label>
                            </li>
                            <li>
                                <label :for="`img_text-${uniqueId}`">
                                    <input type="radio" :id="`img_text-${uniqueId}`" :value="2" v-model="form.cover_type" hidden>
                                    <img src="@/assets/images/picture.svg" alt="">
                                    <h5>Image + Text</h5>
                                </label>
                            </li>
                            <li>
                                <label :for="`img_only-${uniqueId}`">
                                    <input type="radio" :id="`img_only-${uniqueId}`" :value="3" v-model="form.cover_type" hidden>
                                    <img src="@/assets/images/picture.svg" alt="">
                                    <h5>Image Only</h5>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="setting_wpr" v-if="form.display_cover">
                        <div class="form_grp mt-1">
                            <div class="group_item">
                                <label class="input_label">Name</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`title_${formType}`] }">
                                    <Field autocomplete="off" :name="`title_${formType}`" type="text" v-model="form.title"  placeholder="ex: This is only visible to you." label="name" />
                                </div>
                                <ErrorMessage :name="`title_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="color_container mt-3" v-if="form.cover_type !== 3 && form.display_cover">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Headline <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                <div class="field_wpr" :class="{ 'has-error': errors[`headline_${formType}`] }">
                                    <Field autocomplete="off" type="text" :name="`headline_${formType}`" v-model="form.headline" label="headline"  placeholder="Compelling Headline Goes Here" />
                                </div>
                                <ErrorMessage :name="`headline_${formType}`" class="text-danger" />
                            </div>
                        </div>
                        <div class="mt-4" v-show="headlineSetting">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Font-Family</label>
                                    <div class="field_wpr m-0">
                                        <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                    </div>
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Font-Weight</label>
                                    <div class="field_wpr m-0">
                                        <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Size</label>
                                    <div class="field_wpr m-0">
                                        <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Color</label>
                                    <Field autocomplete="off" name="cover_textcolor" v-model="form.headline_setting.font_color" type="text" label="element color">
                                        <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.cover_textcolor }" />
                                    </Field>
                                    <ErrorMessage name="cover_textcolor" class="text-danger" />
                                </div>
                            </div>
                            <div class="capsule_elm">
                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                <label :for="`headline_shadow-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`headline_shadow-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div v-if="form.headline_setting.has_shadow">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Offset-x</label>
                                        <div class="field_wpr">
                                            <Field autocomplete="off" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <label class="input_label">Offset-y</label>
                                        <div class="field_wpr ">
                                            <Field autocomplete="off" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-3">
                                    <div class="group_item">
                                        <label class="input_label">Blur-Radius</label>
                                        <div class="field_wpr">
                                            <Field autocomplete="off" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Shadow Color</label>
                                        <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                            <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                        </Field>
                                        <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="color_container mt-3" v-if="form.cover_type !== 3 && form.display_cover">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Sub Headline <button type="button" class="px-1" @click="subHeadlineSetting = !subHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                <div class="field_wpr" :class="{ 'has-error': errors[`sub_headline_${formType}`] }">
                                    <Field autocomplete="off" type="text" :name="`sub_headline_${formType}`" v-model="form.sub_headline" label="sub headline" placeholder="Enter text here" />
                                </div>
                                <ErrorMessage :name="`sub_headline_${formType}`" class="text-danger" />
                            </div>
                        </div>
                        <div class="mt-4 mb-4" v-show="subHeadlineSetting">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Font-Family</label>
                                    <div class="field_wpr m-0">
                                        <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                    </div>
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Font-Weight</label>
                                    <div class="field_wpr m-0">
                                        <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Size</label>
                                    <div class="field_wpr m-0">
                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Color</label>
                                    <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                        <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                    </Field>
                                    <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                </div>
                            </div>
                            <div class="capsule_elm">
                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                <label :for="`subheadline_shadow-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`subheadline_shadow-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div v-if="form.subheadline_setting.has_shadow">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Offset-x</label>
                                        <div class="field_wpr">
                                            <Field autocomplete="off" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <label class="input_label">Offset-y</label>
                                        <div class="field_wpr ">
                                            <Field autocomplete="off" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-3">
                                    <div class="group_item">
                                        <label class="input_label">Blur-Radius</label>
                                        <div class="field_wpr">
                                            <Field autocomplete="off" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Shadow Color</label>
                                        <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                            <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                        </Field>
                                        <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-show="form.display_cover">
                <div class="section_header">
                    <h2>Cover</h2>
                </div>
                <div class="color_container mt-1" v-if="form.cover_type !== 1">
                    <image-library v-model="form.ep_logo" image-type="public-cover" upload-text="Cover" />
                    <div class="border-bottom pb-2 mb-2">
                        <div class="capsule_elm">
                            <h5>Small Screen Image?</h5>
                            <label :for="`small_image-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`small_image-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <image-library v-if="form.has_small_screen_cover" v-model="form.ep_logo_small" image-type="public-cover-small" upload-text="Cover" />
                    </div>
                    <div class="capsule_elm">
                        <h5>Add Image Overlay?</h5>
                        <label :for="`overlay-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="checkbox" :id="`overlay-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.has_cover_overlay" class="setting_wpr mt-2">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Overlay Color</label>
                                <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                    <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                </Field>
                                <ErrorMessage name="overlay_color" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Overlay opacity</label>
                                <div class="field_wpr" :class="{ 'has-error': errors[`overlay_opacity_${formType}`] }">
                                    <Field autocomplete="off" type="number" :name="`overlay_opacity_${formType}`" v-model="form.overlay_opacity" min="0" max="100" label="overlay opacity"  placeholder="68" />
                                </div>
                                <ErrorMessage :name="`overlay_opacity_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="color_container mt-1" v-if="form.cover_type === 1">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Background Color</label>
                                <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                    <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                </Field>
                                <ErrorMessage name="cover_background_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="color_container mt-2">
                    <ul class="tab_sec mb-5">
                        <li @click="form.coverTab = 'bigScreen'" :class="{ active: form.coverTab === 'bigScreen' }">Big Screen</li>
                        <li @click="form.coverTab = 'smallScreen'" :class="{ active: form.coverTab === 'smallScreen' }">Small Screen</li>
                    </ul>
                    <div v-if="form.coverTab === 'bigScreen'">
                        <image-library v-model="form.ep_logo" image-type="public-cover" upload-text="Cover" />
                    </div>
                    <div v-if="form.coverTab === 'smallScreen'">
                        <image-library v-model="form.ep_logo_small" image-type="public-cover-small" upload-text="Cover" />
                    </div>
                </div> -->
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'bio'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Bio Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Coach Section</h5>
                            <label :for="`display-coach-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`display-coach-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.display_coach" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="mt-2" v-if="form.display_coach">
                            <div class="form_grp mt-1">
                                <div class="group_item">
                                    <label class="input_label">Coach Headline</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`coach_headline_${formType}`] }">
                                        <Field autocomplete="off" :name="`coach_headline_${formType}`" type="text" v-model="form.coach_headline" label="coach headline" placeholder="ex: Synergy Fitness to Synergy" />
                                    </div>
                                    <ErrorMessage :name="`coach_headline_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-1">
                                <div class="group_item">
                                    <label class="input_label">Coach Name</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`coach_name_${formType}`] }">
                                        <Field autocomplete="off" :name="`coach_name_${formType}`" type="text" v-model="form.coach_name" label="coach name" placeholder="ex: Synergy Fitness to Synergy" />
                                    </div>
                                    <ErrorMessage :name="`coach_name_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-1">
                                <div class="group_item">
                                    <label class="input_label">Coach Title</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`coach_title_${formType}`] }">
                                        <Field autocomplete="off" :name="`coach_title_${formType}`" type="text" v-model="form.coach_title" label="coach title" placeholder="ex: Synergy Fitness to Synergy" />
                                    </div>
                                    <ErrorMessage :name="`coach_title_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-1">
                            <coach-bio-component v-model="form.coach_bio" :default-value="defaultCoachBio" :reset-to-default-watcher="resetToDefaultWatcher" :errors="errors" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-if="form.display_coach">
                <div class="section_header">
                    <h2>Coach Image</h2>
                </div>
                <div class="color_container mt-2">
                    <image-library v-model="form.coach_image" image-type="avatar" />
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'action' && formType == 'playbook'">
            <!-- <div class="section_content w-100">
                <div class="section_header">
                    <h2>Action Buttons</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Apply Tag</h5>
                            <label :for="`tag-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`tag-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.apply_tag" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp" v-if="form.apply_tag">
                            <div class="group_item">
                                <label class="input_label">
                                    Select Tags
                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                </label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                    <Field autocomplete="off" name="tag" v-model="form.selected_tags">
                                        <multiselect
                                            v-model="form.selected_tags"
                                            mode="tags"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :group-select="true"
                                            :options="tagsGroup"
                                            :groups="true"
                                            group-label="type"
                                            group-options="tags"
                                            :group-hide-empty="true"
                                            placeholder="Select Tags"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.name }}</span>
                                            </template>
                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                {{ option.name }}
                                                <span
                                                    v-if="!disabled"
                                                    class="multiselect-tag-remove"
                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                >
                                                    <span class="multiselect-tag-remove-icon"></span>
                                                </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="tag" class="text-danger" />
                            </div>
                        </div>
                        <div v-if="createTags && form.apply_tag" class="form_grp">
                            <Form v-slot="{ errors }" ref="tag-form">
                                <div class="group_item">
                                    <label class="input_label">Create a New Tag</label>
                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-if="form.apply_tag">
                <div class="section_header">
                    <h2>Tag Option</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="capsule_elm border-bottom pb-2 mb-2">
                        <h5>A Question Is Asked</h5>
                        <label :for="`asked-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="radio" :id="`asked-${uniqueId}`" value="1" v-model="form.tag_option" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="capsule_elm border-bottom pb-2 mb-2">
                        <h5>Understand Procedure</h5>
                        <label :for="`understand-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="radio" :id="`understand-${uniqueId}`" value="2" v-model="form.tag_option" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="capsule_elm">
                        <h5>Completes Procedure</h5>
                        <label :for="`completes-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="radio" :id="`completes-${uniqueId}`" value="3" v-model="form.tag_option" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
            </div> -->
            <div class="section_content w-100">
                <ul class="tab_sec m-0">
                    <li @click="form.actionTab = 'memberQuestion'" :class="{active: form.actionTab === 'memberQuestion'}">Asks a Question</li>
                    <li @click="form.actionTab = 'memberUnderstood'" :class="{active: form.actionTab === 'memberUnderstood'}">Playbook Understood</li>
                    <li @click="form.actionTab = 'memberCompletes'" :class="{active: form.actionTab === 'memberCompletes'}">Playbook Completed</li>
                </ul>
            </div>
            <div class="w-100" v-if="form.actionTab === 'memberQuestion'">
                <div class="section_content w-100 pt-0">
                    <div class="color_container mt-1 mb-2">
                        <div class="setting_wpr">
                            <div class="capsule_elm">
                                <h5>Display Button</h5>
                                <label :for="`display-ask-button-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`display-ask-button-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.recipient_ask.display" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="mt-2" v-if="form.recipient_ask.display">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Text</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_ask_button_text }">
                                            <Field autocomplete="off" name="recipient_ask_button_text" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_ask.button_text" label="button text" />
                                        </div>
                                        <ErrorMessage name="recipient_ask_button_text" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Link</label>
                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.recipient_ask_button_link }">
                                            <Field autocomplete="off" name="recipient_ask_button_link" type="text" placeholder="https://superfitcoaching.com" v-model="form.recipient_ask.link" label="button link" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="recipient_ask_button_link" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button color</label>
                                        <Field autocomplete="off" name="recipient_ask_button_color" v-model="form.recipient_ask.button_color" type="text" label="button text color">
                                            <color-picker v-model="form.recipient_ask.button_color" :classes="{ 'has-error': errors.recipient_ask_button_color }" />
                                        </Field>
                                        <ErrorMessage name="recipient_ask_button_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button text color</label>
                                        <Field autocomplete="off" name="recipient_ask_button_txt_color" v-model="form.recipient_ask.button_txt_color" type="text" label="button text color">
                                            <color-picker v-model="form.recipient_ask.button_txt_color" :classes="{ 'has-error': errors.recipient_ask_button_txt_color }" />
                                        </Field>
                                        <ErrorMessage name="recipient_ask_button_txt_color" class="text-danger" />
                                    </div>
                                </div>
                                <h3 class="sub_heading2 mt-3">Notification Method</h3>
                                <div class="edit_section border mb-2">
                                    <sending-method v-model="form.recipient_ask.notification_method" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="form.recipient_ask.display">
                        <div v-if="form.recipient_ask.notification_method === 1 || form.recipient_ask.notification_method === 3">
                            <div class="capsule_elm mb-1 mt-2">
                                <h3 class="sub_heading2 m-0">Email Setup</h3>
                                <label :for="`recipient-ask-email-${uniqueId}`" class="switch_option capsule_btn m-0">
                                    <input type="checkbox" :id="`recipient-ask-email-${uniqueId}`" v-model="form.recipient_ask.enabled_email_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr mb-4" v-if="form.recipient_ask.enabled_email_notification">
                                <email-component v-model="recipientAskEmailContent" :errors="errors" :handle-default-email="handleDefaultEmailRecipientAsk" :has-recipient="true" ref="recipient-ask-email-component" />
                            </div>
                        </div>
                        <div v-if="form.recipient_ask.notification_method === 2 || form.recipient_ask.notification_method === 3">
                            <div class="capsule_elm mt-2">
                                <h3 class="sub_heading2 m-0">SMS Setup</h3>
                                <label :for="`recipient-ask-sms-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`recipient-ask-sms-${uniqueId}`" v-model="form.recipient_ask.enabled_sms_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr" v-if="form.recipient_ask.enabled_sms_notification">
                                <div class="form_grp pt-2">
                                    <div class="group_item">
                                        <label class="input_label">Contact Number</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_ask_sms_recipient }">
                                            <Field autocomplete="off" name="recipient_ask_sms_recipient" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_ask.sms_recipient" rules="required" label="contact number" />
                                        </div>
                                    </div>
                                    <ErrorMessage name="recipient_ask_sms_recipient" class="text-danger" />
                                </div>
                                <sms-component v-model="form.recipient_ask.sms_message" :media="recipientAskSmsMedia" media-field-name="recipientAskSmsMedia" :default-sms-handler="true" :handle-default-sms="handleDefaultSmsRecipientAsk" ref="recipient-ask-sms-component" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="w-100" v-if="form.actionTab === 'memberUnderstood'">
                <div class="section_content w-100 pt-0">
                    <div class="color_container mt-1 mb-2">
                        <div class="setting_wpr">
                            <div class="capsule_elm">
                                <h5>Display Button</h5>
                                <label :for="`display-understand-button-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`display-understand-button-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.recipient_understand.display" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="mt-2" v-if="form.recipient_understand.display">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Text</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_understand_button_text }">
                                            <Field autocomplete="off" name="recipient_understand_button_text" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_understand.button_text" label="button text" />
                                        </div>
                                        <ErrorMessage name="recipient_understand_button_text" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Link</label>
                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.recipient_understand_button_link }">
                                            <Field autocomplete="off" name="recipient_understand_button_link" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_understand.link" label="button link" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="recipient_understand_button_link" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button color</label>
                                        <Field autocomplete="off" name="recipient_understand_button_color" v-model="form.recipient_understand.button_color" type="text" label="button text color">
                                            <color-picker v-model="form.recipient_understand.button_color" :classes="{ 'has-error': errors.recipient_understand_button_color }" />
                                        </Field>
                                        <ErrorMessage name="recipient_understand_button_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button text color</label>
                                        <Field autocomplete="off" name="recipient_understand_button_txt_color" v-model="form.recipient_understand.button_txt_color" type="text" label="button text color">
                                            <color-picker v-model="form.recipient_understand.button_txt_color" :classes="{ 'has-error': errors.recipient_understand_button_txt_color }" />
                                        </Field>
                                        <ErrorMessage name="recipient_understand_button_txt_color" class="text-danger" />
                                    </div>
                                </div>
                                <h3 class="sub_heading2 mt-3">Notification Method</h3>
                                <div class="edit_section border mb-2">
                                    <sending-method v-model="form.recipient_understand.notification_method" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="form.recipient_understand.display">
                        <div v-if="form.recipient_understand.notification_method === 1 || form.recipient_understand.notification_method === 3">
                            <div class="capsule_elm mb-1 mt-2">
                                <h3 class="sub_heading2 m-0">Email Setup</h3>
                                <label :for="`recipient-understand-email-${uniqueId}`" class="switch_option capsule_btn m-0">
                                    <input type="checkbox" :id="`recipient-understand-email-${uniqueId}`" v-model="form.recipient_understand.enabled_email_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr mb-4" v-if="form.recipient_understand.enabled_email_notification">
                                <email-component v-model="recipientUnderstandEmailContent" :errors="errors" :handle-default-email="handleDefaultEmailRecipientUnderstand" :has-recipient="true" ref="recipient-understand-email-component" />
                            </div>
                        </div>
                        <div v-if="form.recipient_understand.notification_method === 2 || form.recipient_understand.notification_method === 3">
                            <div class="capsule_elm mt-2">
                                <h3 class="sub_heading2 m-0">SMS Setup</h3>
                                <label :for="`recipient-understand-sms-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`recipient-understand-sms-${uniqueId}`" v-model="form.recipient_understand.enabled_sms_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr" v-if="form.recipient_understand.enabled_sms_notification">
                                <div class="form_grp pt-2">
                                    <div class="group_item">
                                        <label class="input_label">Contact Number</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_understand_sms_recipient }">
                                            <Field autocomplete="off" name="recipient_understand_sms_recipient" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_understand.sms_recipient" rules="required" label="contact number" />
                                        </div>
                                    </div>
                                    <ErrorMessage name="recipient_understand_sms_recipient" class="text-danger" />
                                </div>
                                <sms-component v-model="form.recipient_understand.sms_message" :media="recipientUnderstandSmsMedia" media-field-name="recipientUnderstandSmsMedia" :default-sms-handler="true" :handle-default-sms="handleDefaultSmsRecipientUnderstand" ref="recipient-understand-sms-component" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="w-100" v-if="form.actionTab === 'memberCompletes'">
                <div class="section_content w-100 pt-0">
                    <div class="color_container mt-1 mb-2">
                        <div class="setting_wpr">
                            <div class="capsule_elm">
                                <h5>Display Button</h5>
                                <label :for="`display-complete-button-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`display-complete-button-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.recipient_complete.display" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="mt-2" v-if="form.recipient_complete.display">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Text</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_complete_button_text }">
                                            <Field autocomplete="off" name="recipient_complete_button_text" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_complete.button_text" label="button text" />
                                        </div>
                                        <ErrorMessage name="recipient_complete_button_text" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Link</label>
                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.recipient_complete_button_link }">
                                            <Field autocomplete="off" name="recipient_complete_button_link" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_complete.link" label="button link" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="recipient_complete_button_link" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button color</label>
                                        <Field autocomplete="off" name="recipient_complete_button_color" v-model="form.recipient_complete.button_color" type="text" label="button text color">
                                            <color-picker v-model="form.recipient_complete.button_color" :classes="{ 'has-error': errors.recipient_complete_button_color }" />
                                        </Field>
                                        <ErrorMessage name="recipient_complete_button_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button text color</label>
                                        <Field autocomplete="off" name="recipient_complete_button_txt_color" v-model="form.recipient_complete.button_txt_color" type="text" label="button text color">
                                            <color-picker v-model="form.recipient_complete.button_txt_color" :classes="{ 'has-error': errors.recipient_complete_button_txt_color }" />
                                        </Field>
                                        <ErrorMessage name="recipient_complete_button_txt_color" class="text-danger" />
                                    </div>
                                </div>
                                <h3 class="sub_heading2 mt-3">Notification Method</h3>
                                <div class="edit_section border mb-2">
                                    <sending-method v-model="form.recipient_complete.notification_method" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="form.recipient_complete.display">
                        <div v-if="form.recipient_complete.notification_method === 1 || form.recipient_complete.notification_method === 3">
                            <div class="capsule_elm mb-1 mt-2">
                                <h3 class="sub_heading2 m-0">Email Setup</h3>
                                <label :for="`recipient-complete-email-${uniqueId}`" class="switch_option capsule_btn m-0">
                                    <input type="checkbox" :id="`recipient-complete-email-${uniqueId}`" v-model="form.recipient_complete.enabled_email_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr mb-4" v-if="form.recipient_complete.enabled_email_notification">
                                <email-component v-model="recipientCompleteEmailContent" :errors="errors" :handle-default-email="handleDefaultEmailRecipientComplete" :has-recipient="true" ref="recipient-complete-email-component" />
                            </div>
                        </div>
                        <div v-if="form.recipient_complete.notification_method === 2 || form.recipient_complete.notification_method === 3">
                            <div class="capsule_elm mt-2">
                                <h3 class="sub_heading2 m-0">SMS Setup</h3>
                                <label :for="`recipient-complete-sms-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`recipient-complete-sms-${uniqueId}`" v-model="form.recipient_complete.enabled_sms_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr" v-if="form.recipient_complete.enabled_sms_notification">
                                <div class="form_grp pt-2">
                                    <div class="group_item">
                                        <label class="input_label">Contact Number</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_complete_sms_recipient }">
                                            <Field autocomplete="off" name="recipient_complete_sms_recipient" type="text" placeholder="ex: Synergy Fitness to Synergy" v-model="form.recipient_complete.sms_recipient" rules="required" label="contact number" />
                                        </div>
                                    </div>
                                    <ErrorMessage name="recipient_complete_sms_recipient" class="text-danger" />
                                </div>
                                <sms-component v-model="form.recipient_complete.sms_message" :media="recipientCompleteSmsMedia" media-field-name="recipientCompleteSmsMedia" :default-sms-handler="true" :handle-default-sms="handleDefaultSmsRecipientComplete" ref="recipient-complete-sms-component" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'button'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Button</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Button</h5>
                            <label :for="`button-display-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`button-display-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.cta_button.display" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="mt-1" v-if="form.cta_button.display">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Button Text</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.cta_button_text }">
                                        <Field autocomplete="off" name="cta_button_text" type="text" :placeholder="`© ${ (new Date).getYear() } - Thrive Coach`" v-model="form.cta_button.text" />
                                    </div>
                                    <ErrorMessage name="cta_button_text" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp" v-if="form.cta_button.option == 'redirect'">
                                <div class="group_item">
                                    <label class="input_label">Button Link</label>
                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.cta_button_url }">
                                        <Field autocomplete="off" name="cta_button_url" type="text" placeholder="https://superfitcoaching.com" v-model="form.cta_button.url" rules="url" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                    <ErrorMessage name="cta_button_url" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Button color</label>
                                    <Field autocomplete="off" name="cta_button_color" v-model="form.cta_button.btn_color" type="text" label="button text color">
                                        <color-picker v-model="form.cta_button.btn_color" :classes="{ 'has-error': errors.cta_button_color }" />
                                    </Field>
                                    <ErrorMessage name="cta_button_color" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Button text color</label>
                                    <Field autocomplete="off" name="cta_button_txt_color" v-model="form.cta_button.btn_text_color" type="text" label="button text color">
                                        <color-picker v-model="form.cta_button.btn_text_color" :classes="{ 'has-error': errors.cta_button_txt_color }" />
                                    </Field>
                                    <ErrorMessage name="cta_button_txt_color" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-if="form.cta_button.display">
                <div class="section_header">
                    <h2>Button Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="capsule_elm pb-2 mb-2 border-bottom">
                        <h5>Left Align</h5>
                        <label :for="`left-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="radio" :id="`left-${uniqueId}`" value="left" v-model="form.cta_button.position" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="capsule_elm pb-2 mb-2 border-bottom">
                        <h5>Center Align</h5>
                        <label :for="`center-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="radio" :id="`center-${uniqueId}`" value="center" v-model="form.cta_button.position" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="capsule_elm">
                        <h5>Right Align</h5>
                        <label :for="`right-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="radio" :id="`right-${uniqueId}`" value="right" v-model="form.cta_button.position" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="color_container mt-3">
                    <div class="capsule_elm pb-2 mb-2 border-bottom">
                        <h5>Redirect</h5>
                        <label :for="`redirect-${uniqueId}`" class="switch_option capsule_btn">
                            <input name="option" type="radio" :id="`redirect-${uniqueId}`" value="redirect" v-model="form.cta_button.option" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="capsule_elm">
                        <h5>Popup</h5>
                        <label :for="`popup-${uniqueId}`" class="switch_option capsule_btn">
                            <input name="option" type="radio" :id="`popup-${uniqueId}`" value="popup" v-model="form.cta_button.option" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'progress' && (formType == 'playbook' || formType == 'form')">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Progress Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Progress</h5>
                            <label :for="`form-progress-bar-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`form-progress-bar-${uniqueId}`" v-model="form.display_progressbar" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp mt-1" v-if="form.display_progressbar">
                            <div class="group_item">
                                <label class="input_label">Progress bar color</label>
                                <Field autocomplete="off" name="progressbar_color" v-model="form.progressbar_color" type="text" rules="required" label="progress bar color">
                                    <color-picker v-model="form.progressbar_color" :classes="{ 'has-error': errors.progressbar_color }" />
                                </Field>
                                <ErrorMessage name="progressbar_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'footer'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Footer</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Footer</h5>
                            <label :for="`form-footer-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`form-footer-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="mt-2" v-if="form.display_footer">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Copyright Text</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_company_${formType}`] }">
                                        <Field autocomplete="off" type="text" :name="`footer_company_${formType}`" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" v-model="form.footer_company" label="copyright text" />
                                    </div>
                                    <ErrorMessage :name="`footer_company_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Terms Link</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_terms_${formType}`] }">
                                        <Field autocomplete="off" :name="`footer_terms_${formType}`" type="text" placeholder="ex: https://clientportal.com/terms" v-model="form.footer_terms" rules="url" label="terms link" />
                                    </div>
                                    <ErrorMessage :name="`footer_terms_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Privacy Link</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_policy_${formType}`] }">
                                        <Field autocomplete="off" :name="`footer_policy_${formType}`" type="text" placeholder="ex: https://clientportal.com/privacy" v-model="form.footer_policy" rules="url" label="privacy link" />
                                    </div>
                                    <ErrorMessage :name="`footer_policy_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Background Color</label>
                                    <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="background color">
                                        <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                    </Field>
                                    <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Text color</label>
                                    <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="text color">
                                        <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                    </Field>
                                    <ErrorMessage name="footer_textcolor" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-if="form.display_footer">
                <div class="section_header">
                    <h2>Social Icons</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Facebook</h5>
                            <label :for="`footer-facebook-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`footer-facebook-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_footer_facebook" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.has_footer_facebook">
                            <div class="group_item">
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_facebook_${formType}`] }">
                                    <Field autocomplete="off" :name="`footer_facebook_${formType}`" type="text" placeholder="ex: https://facebook.com" v-model="form.footer_facebook" rules="url|validate_url:facebook" label="facebook" />
                                </div>
                                <ErrorMessage :name="`footer_facebook_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Twitter</h5>
                            <label :for="`footer-twitter-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`footer-twitter-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_footer_twitter" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.has_footer_twitter">
                            <div class="group_item">
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_twitter_${formType}`] }">
                                    <Field autocomplete="off" :name="`footer_twitter_${formType}`" type="text" placeholder="ex: https://twitter.com" v-model="form.footer_twitter" rules="url|validate_url:twitter" label="twitter" />
                                </div>
                                <ErrorMessage :name="`footer_twitter_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5 class="large">Instagram</h5>
                            <label :for="`footer-instagram-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`footer-instagram-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_footer_instagram" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.has_footer_instagram">
                            <div class="group_item">
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_instagram_${formType}`] }">
                                    <Field autocomplete="off" :name="`footer_instagram_${formType}`" type="text" placeholder="ex: https://instagram.com" v-model="form.footer_instagram" rules="url|validate_url:instagram" label="instagram" />
                                </div>
                                <ErrorMessage :name="`footer_instagram_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5 class="large">Linkedin</h5>
                            <label :for="`footer-linkedin-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`footer-linkedin-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_footer_linkedin" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.has_footer_linkedin">
                            <div class="group_item">
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_linkedin_${formType}`] }">
                                    <Field autocomplete="off" :name="`footer_linkedin_${formType}`" type="text" placeholder="ex: https://linkedin.com" v-model="form.footer_linkedin" rules="url|validate_url:linkedin" label="linkedin" />
                                </div>
                                <ErrorMessage :name="`footer_linkedin_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5 class="large">Youtube</h5>
                            <label :for="`footer-youtube-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`footer-youtube-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.has_footer_youtube" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.has_footer_youtube">
                            <div class="group_item">
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`footer_youtube_${formType}`] }">
                                    <Field autocomplete="off" :name="`footer_youtube_${formType}`" type="text" placeholder="ex: https://youtube.com" v-model="form.footer_youtube" rules="url|validate_url:youtu" label="youtube" />
                                </div>
                                <ErrorMessage :name="`footer_youtube_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="formType == 'progress-tracking'  || formType == 'habit-tracking'">
        <!-- <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="tab_slider topTabs">
            <swiper-slide><span @click="form.tab = 'header'" :class="{active: form.tab === 'header'}">Header</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'cover'" :class="{active: form.tab === 'cover'}">Cover</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'color'" :class="{active: form.tab === 'color'}">Color</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'submit-button'" :class="{active: form.tab === 'submit-button'}">Submit Button</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'thank-you'" :class="{active: form.tab === 'thank-you'}">Thank You</span></swiper-slide>
            <swiper-slide><span @click="form.tab = 'footer'" :class="{active: form.tab === 'footer'}">Footer</span></swiper-slide>
        </swiper> -->
        <ul class="tab_sec header">
            <li @click="form.tab = 'header'" :class="{active: form.tab === 'header'}">Header</li>
            <li @click="form.tab = 'cover'" :class="{active: form.tab === 'cover'}">Cover</li>
            <li @click="form.tab = 'color'" :class="{active: form.tab === 'color'}">Color</li>
            <li @click="form.tab = 'submit-button'" :class="{active: form.tab === 'submit-button'}">Submit Button</li>
            <li @click="form.tab = 'thank-you'" :class="{active: form.tab === 'thank-you'}">Thank You</li>
            <li @click="form.tab = 'footer'" :class="{active: form.tab === 'footer'}">Footer</li>
        </ul>
        <div class="content_wpr" v-if="form.tab === 'header'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Header Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Show</h5>
                            <label :for="`display_header-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`display_header-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.display_header" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="setting_wpr outer" v-show="form.public_settings.display_header">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Company Name <a class="reset-default" @click="form.public_settings.company_branding = defaultForm.public_settings.company_branding">Reset to default</a></label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`company_branding_${formType}`] }">
                                        <Field autocomplete="off" type="text" :name="`company_branding_${formType}`" v-model="form.public_settings.company_branding" label="company name" placeholder="Company Name" />
                                    </div>
                                    <ErrorMessage :name="`company_branding_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Header Background Color <a class="reset-default" @click="form.public_settings.header_bgcolor = defaultForm.public_settings.header_bgcolor">Reset to default</a></label>
                                    <Field autocomplete="off" name="header_bgcolor" type="text" label="header background color">
                                        <color-picker v-model="form.public_settings.header_bgcolor"/>
                                    </Field>
                                    <ErrorMessage name="header_bgcolor" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Header Text Color <a class="reset-default" @click="form.public_settings.header_textcolor = defaultForm.public_settings.header_textcolor">Reset to default</a></label>
                                    <Field autocomplete="off" name="header_textcolor" type="text" label="header text color">
                                        <color-picker v-model="form.public_settings.header_textcolor"/>
                                    </Field>
                                    <ErrorMessage name="header_textcolor" class="text-danger" />
                                </div>
                            </div>
                            <ul class="tab_sec bg-transparent m-0 mt-2">
                                <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                            </ul>
                            <div class="setting_wpr pt-3">
                                <div v-if="headerTab === 'logo'">
                                    <div class="capsule_elm">
                                        <h5>Display Logo</h5>
                                        <label :for="`show_logo-${uniqueId}`" class="switch_option capsule_btn">
                                            <input type="checkbox" :id="`show_logo-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.display_logo" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="mt-1" v-if="form.public_settings.display_logo">
                                        <image-library image-type="public-logo" upload-text="Logo" v-model="form.public_settings.logo" />
                                    </div>
                                </div>
                                <div v-if="headerTab === 'author'">
                                    <div class="capsule_elm">
                                        <h5>Display Author</h5>
                                        <label :for="`coach_img-${uniqueId}`" class="switch_option capsule_btn">
                                            <input type="checkbox" :id="`coach_img-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.display_author" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="mt-1" v-if="form.public_settings.display_author">
                                        <image-library image-type="avatar" :is-avatar="true" upload-text="Image" v-model="form.public_settings.author" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'cover'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Cover Settings</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="capsule_elm">
                        <h5>Show
                            <span v-if="!form.public_settings.small_cover">Upload a small screen cover for a better view</span>
                        </h5>
                        <label :for="`cover-${uniqueId}`" class="switch_option capsule_btn" @click="handleALertPrompt()">
                            <input type="checkbox" :id="`cover-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.display_cover" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.public_settings.display_cover">
                        <div class="cover_type">
                            <ul class="type_list">
                                <li>
                                    <label :for="`clr_text-${uniqueId}`">
                                        <input type="radio" :id="`clr_text-${uniqueId}`" :value="1" v-model="form.public_settings.cover_type" hidden>
                                        <img src="@/assets/images/background.svg" alt="">
                                        <h5>Color + Text</h5>
                                    </label>
                                </li>
                                <li>
                                    <label :for="`img_text-${uniqueId}`">
                                        <input type="radio" :id="`img_text-${uniqueId}`" :value="2" v-model="form.public_settings.cover_type" hidden>
                                        <img src="@/assets/images/picture.svg" alt="">
                                        <h5>Image + Text</h5>
                                    </label>
                                </li>
                                <li>
                                    <label :for="`img_only-${uniqueId}`">
                                        <input type="radio" :id="`img_only-${uniqueId}`" :value="3" v-model="form.public_settings.cover_type" hidden>
                                        <img src="@/assets/images/picture.svg" alt="">
                                        <h5>Image Only</h5>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Headline
                                        <div class="flex-center">
                                            <button type="button" class="reset-default" @click="form.public_settings.headline = defaultForm.public_settings.headline">Reset to default</button>
                                            <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                        </div>
                                    </label>
                                    <div class="field_wpr" :class="{ 'has-error': errors[`headline_${formType}`] }">
                                        <Field autocomplete="off" type="text" :name="`headline_${formType}`" v-model="form.public_settings.headline" label="headline" placeholder="Compelling Headline Goes Here" />
                                    </div>
                                    <ErrorMessage :name="`headline_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div v-if="headlineSetting">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Font-Family <a class="reset-default" @click="form.headline_setting.font_family = defaultForm.headline_setting.font_family">Reset to default</a></label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <label class="input_label">Font-Weight <a class="reset-default" @click="form.headline_setting.font_weight = defaultForm.headline_setting.font_weight">Reset to default</a></label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Size <a class="reset-default" @click="form.headline_setting.font_size = defaultForm.headline_setting.font_size">Reset to default</a></label>
                                        <div class="field_wpr m-0">
                                            <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Color <a class="reset-default" @click="form.headline_setting.font_color = defaultForm.headline_setting.font_color">Reset to default</a></label>
                                        <Field autocomplete="off" name="headline_setting_cover_textcolor" v-model="form.headline_setting.font_color" type="text" label="element color">
                                            <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_cover_textcolor }" />
                                        </Field>
                                        <ErrorMessage name="headline_setting_cover_textcolor" class="text-danger" />
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div class="capsule_elm">
                                        <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                        <label :for="`headline_shadow-${uniqueId}`" class="switch_option capsule_btn">
                                            <input type="checkbox" :id="`headline_shadow-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.headline_setting.has_shadow">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Offset-x <a class="reset-default" @click="form.headline_setting.shadow_x = defaultForm.headline_setting.shadow_x">Reset to default</a></label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Offset-y <a class="reset-default" @click="form.headline_setting.shadow_y = defaultForm.headline_setting.shadow_y">Reset to default</a></label>
                                                <div class="field_wpr ">
                                                    <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Blur-Radius <a class="reset-default" @click="form.public_settings.shadow_blur = defaultForm.public_settings.shadow_blur">Reset to default</a></label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Shadow Color <a class="reset-default" @click="form.public_settings.shadow_color = defaultForm.public_settings.shadow_color">Reset to default</a></label>
                                                <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                    <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                </Field>
                                                <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Sub Headline
                                        <div class="flex-center">
                                            <button type="button" class="reset-default" @click="form.public_settings.subheadline = defaultForm.public_settings.subheadline">Reset to default</button>
                                            <button type="button" class="px-1" @click="subHeadlineSetting = !subHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                        </div>
                                    </label>
                                    <div class="field_wpr" :class="{ 'has-error': errors[`sub_headline_${formType}`] }">
                                        <Field autocomplete="off" type="text" :name="`sub_headline_${formType}`" v-model="form.public_settings.subheadline" label="sub headline" placeholder="This can be edited or changed to a cover image by clicking here" />
                                    </div>
                                    <ErrorMessage :name="`sub_headline_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div v-if="subHeadlineSetting">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Font-Family <a class="reset-default" @click="form.subheadline_setting.font_family = defaultForm.subheadline_setting.font_family">Reset to default</a></label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <label class="input_label">Font-Weight <a class="reset-default" @click="form.subheadline_setting.font_weight = defaultForm.subheadline_setting.font_weight">Reset to default</a></label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Size <a class="reset-default" @click="form.subheadline_setting.font_size = defaultForm.subheadline_setting.font_size">Reset to default</a></label>
                                        <div class="field_wpr m-0">
                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Color <a class="reset-default" @click="form.subheadline_setting.font_color = defaultForm.subheadline_setting.font_color">Reset to default</a></label>
                                        <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                            <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                        </Field>
                                        <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div class="capsule_elm">
                                        <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                        <label :for="`subheadline_shadow-${uniqueId}`" class="switch_option capsule_btn">
                                            <input type="checkbox" :id="`subheadline_shadow-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.subheadline_setting.has_shadow">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Offset-x <a class="reset-default" @click="form.subheadline_setting.shadow_x = defaultForm.subheadline_setting.shadow_x">Reset to default</a></label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Offset-y <a class="reset-default" @click="form.subheadline_setting.shadow_y = defaultForm.subheadline_setting.shadow_y">Reset to default</a></label>
                                                <div class="field_wpr ">
                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Blur-Radius <a class="reset-default" @click="form.subheadline_setting.shadow_blur = defaultForm.subheadline_setting.shadow_blur">Reset to default</a></label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Shadow Color <a class="reset-default" @click="form.subheadline_setting.shadow_color = defaultForm.subheadline_setting.shadow_color">Reset to default</a></label>
                                                <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                    <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                </Field>
                                                <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr mt-2" v-if="form.public_settings.cover_type !== 1">
                            <h3 class="sub_header mt-1">Cover Image</h3>
                            <image-library image-type="public-cover" upload-text="Cover" v-model="form.public_settings.cover" />
                            <div class="border-bottom pb-2 mb-2">
                                <div class="capsule_elm">
                                    <h5>Small Screen Image</h5>
                                    <label :for="`small_image-${uniqueId}`" class="switch_option capsule_btn">
                                        <input type="checkbox" :id="`small_image-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.has_small_cover" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <image-library v-if="form.public_settings.has_small_cover" image-type="public-cover-small" upload-text="Cover" v-model="form.public_settings.small_cover" />
                            </div>
                            <div class="capsule_elm">
                                <h5>Add Image Overlay</h5>
                                <label :for="`overlay-${uniqueId}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`overlay-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.has_cover_overlay" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div v-if="form.public_settings.has_cover_overlay" class="setting_wpr mt-2">
                                <div class="color_container mt-2">
                                    <div class="group_item">
                                        <label class="input_label">Overlay Color <a class="reset-default" @click="form.public_settings.overlay_color = defaultForm.public_settings.overlay_color">Reset to default</a></label>
                                        <Field autocomplete="off" name="overlay_color" v-model="form.public_settings.overlay_color" type="text" label="element color">
                                            <color-picker v-model="form.public_settings.overlay_color" />
                                        </Field>
                                        <ErrorMessage name="overlay_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Overlay opacity <a class="reset-default" @click="form.public_settings.overlay_opacity = defaultForm.public_settings.overlay_opacity">Reset to default</a></label>
                                        <div class="field_wpr" :class="{ 'has-error': errors[`overlay_opacity_${formType}`] }">
                                            <Field autocomplete="off" type="number" :name="`overlay_opacity_${formType}`" v-model="form.public_settings.overlay_opacity" min="0" max="100" rules="min:0|max:100" label="overlay opacity" placeholder="68" />
                                        </div>
                                        <ErrorMessage :name="`overlay_opacity_${formType}`" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr" v-if="form.public_settings.cover_type === 1">
                            <div class="group_item">
                                <label class="input_label">Cover Background <a class="reset-default" @click="form.public_settings.cover_color = defaultForm.public_settings.cover_color">Reset to default</a></label>
                                <Field autocomplete="off" name="cover_background_color" v-model="form.public_settings.cover_color" type="text" label="element color">
                                    <color-picker v-model="form.public_settings.cover_color" />
                                </Field>
                                <ErrorMessage name="cover_background_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'color'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Color</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Accent Color <a class="reset-default" @click="form.public_settings.element_color = defaultForm.public_settings.element_color">Reset to default</a></label>
                                <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                    <color-picker v-model="form.public_settings.element_color" />
                                </Field>
                                <ErrorMessage name="label_bg_color" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.element_text_color = defaultForm.public_settings.element_text_color">Reset to default</a></label>
                                <Field autocomplete="off" name="label_text_color" type="text" label="label text color">
                                    <color-picker v-model="form.public_settings.element_text_color" />
                                </Field>
                                <ErrorMessage name="label_text_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'submit-button'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Submit Button</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Submit Button Text <a class="reset-default" @click="form.public_settings.submit_btn_text = defaultForm.public_settings.submit_btn_text">Reset to default</a></label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`name_${formType}`] }">
                                    <Field autocomplete="off" type="text" :name="`name_${formType}`" v-model="form.public_settings.submit_btn_text" label="submit button text"  placeholder="ex: Submit Progress" rules="required" />
                                </div>
                                <ErrorMessage :name="`name_${formType}`" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Submit Button Color <a class="reset-default" @click="form.public_settings.submit_btn_bgcolor = defaultForm.public_settings.submit_btn_bgcolor">Reset to default</a></label>
                                <Field autocomplete="off" name="button_bg_color" type="text" label="button background color">
                                    <color-picker v-model="form.public_settings.submit_btn_bgcolor" />
                                </Field>
                                <ErrorMessage name="button_bg_color" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Submit Text Color <a class="reset-default" @click="form.public_settings.submit_btn_textcolor = defaultForm.public_settings.submit_btn_textcolor">Reset to default</a></label>
                                <Field autocomplete="off" name="button_text_color" type="text" label="button text color">
                                    <color-picker v-model="form.public_settings.submit_btn_textcolor" />
                                </Field>
                                <ErrorMessage name="button_text_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'thank-you'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Thank you</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.thankyou_bgcolor = defaultForm.public_settings.thankyou_bgcolor">Reset to default</a></label>
                                <Field autocomplete="off" name="thankyou_bgcolor" v-model="form.public_settings.thankyou_bgcolor" type="text" label="element color">
                                    <color-picker v-model="form.public_settings.thankyou_bgcolor" :classes="{ 'has-error': errors.thankyou_bgcolor }" />
                                </Field>
                                <ErrorMessage name="thankyou_bgcolor" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.thankyou_textcolor = defaultForm.public_settings.thankyou_textcolor">Reset to default</a></label>
                                <Field autocomplete="off" name="thankyou_textcolor" v-model="form.public_settings.thankyou_textcolor" type="text" label="element color">
                                    <color-picker v-model="form.public_settings.thankyou_textcolor" :classes="{ 'has-error': errors.thankyou_textcolor }" />
                                </Field>
                                <ErrorMessage name="thankyou_textcolor" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_wpr" v-if="form.tab === 'footer'">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Footer</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="capsule_elm">
                        <h5>Display Footer</h5>
                        <label :for="`has-login-footer-${uniqueId}`" class="switch_option capsule_btn">
                            <input type="checkbox" :id="`has-login-footer-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.display_footer" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="mt-2" v-show="form.public_settings.display_footer">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Icons Color <a class="reset-default" @click="form.public_settings.footer_textcolor = defaultForm.public_settings.footer_textcolor">Reset to default</a></label>
                                    <Field autocomplete="off" name="footer_textcolor" v-model="form.public_settings.footer_textcolor" type="text" label="element color">
                                        <color-picker v-model="form.public_settings.footer_textcolor"/>
                                    </Field>
                                    <ErrorMessage name="footer_textcolor" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.footer_bgcolor = defaultForm.public_settings.footer_bgcolor">Reset to default</a></label>
                                    <Field autocomplete="off" name="footer_bgcolor" v-model="form.public_settings.footer_bgcolor" type="text" label="element color">
                                        <color-picker v-model="form.public_settings.footer_bgcolor"/>
                                    </Field>
                                    <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Copyright Text</label>
                                    <div class="field_wpr m-0 " >
                                        <Field autocomplete="off" type="text" :name="`footer_company_${formType}`" v-model="form.public_settings.footer_company" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" />
                                    </div>
                                    <ErrorMessage :name="`footer_company_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Terms Link</label>
                                    <div class="field_wpr has_prefix m-0">
                                        <Field autocomplete="off" type="text" :name="`footer_terms_${formType}`" v-model="form.public_settings.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                    <ErrorMessage :name="`footer_terms_${formType}`" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Privacy Link</label>
                                    <div class="field_wpr has_prefix m-0">
                                        <Field autocomplete="off" type="text" :name="`footer_policy_${formType}`" v-model="form.public_settings.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                    <ErrorMessage :name="`footer_policy_${formType}`" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-show="form.public_settings.display_footer">
                <div class="section_header">
                    <h2>Social Icons</h2>
                </div>
                <div class="color_container mt-1">
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Facebook</h5>
                            <label :for="`facebook-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`facebook-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_facebook" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.public_settings.footer_has_facebook">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <Field autocomplete="off" type="text" :name="`footer_facebook_${formType}`" v-model="form.public_settings.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                </div>
                                <ErrorMessage :name="`footer_facebook_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Twitter</h5>
                            <label :for="`twitter-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`twitter-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_twitter" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.public_settings.footer_has_twitter">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <Field autocomplete="off" type="text" :name="`footer_twitter_${formType}`" v-model="form.public_settings.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                </div>
                                <ErrorMessage :name="`footer_twitter_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Instagram</h5>
                            <label :for="`instagram-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`instagram-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_instagram" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.public_settings.footer_has_instagram">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <Field autocomplete="off" type="text" :name="`footer_instagram_${formType}`" v-model="form.public_settings.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                </div>
                                <ErrorMessage :name="`footer_instagram_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Linkedin</h5>
                            <label :for="`linkedin-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`linkedin-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_linkedin" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.public_settings.footer_has_linkedin">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <Field autocomplete="off" type="text" :name="`footer_linkedin_${formType}`" v-model="form.public_settings.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                </div>
                                <ErrorMessage :name="`footer_linkedin_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr mb-1">
                        <div class="capsule_elm">
                            <h5>Youtube</h5>
                            <label :for="`youtube-${uniqueId}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`youtube-${uniqueId}`" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_youtube" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp pt-0" v-if="form.public_settings.footer_has_youtube">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <Field autocomplete="off" type="text" :name="`footer_youtube_${formType}`" v-model="form.public_settings.footer_youtube" placeholder="Instagram link here" rules="url|validate_url:youtu" label="youtube" />
                                </div>
                                <ErrorMessage :name="`footer_youtube_${formType}`" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal secondary" v-if="alert_prompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="alert_icon">
            <h5>You wil need to enable small screen cover image to see your cover settings reflect on the sidebar.</h5>
            <div class="action_wpr mt-3">
                <button type="button" class="btn save_btn" @click="alert_prompt = false;">Okay</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { uuid } from 'vue-uuid'
    import { Form, Field, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    // import SwiperCore, { Navigation } from 'swiper'
    // import { Swiper, SwiperSlide } from 'swiper/vue'

    const CoachBioComponent = defineAsyncComponent(() => import('@/components/CoachBioComponent'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'
    // import 'swiper/swiper-bundle.min.css'
    // SwiperCore.use([Navigation])

    export default {
        name: 'Default Content Form',

        data () {
            return {
                headlineSetting: false,
                subHeadlineSetting: false,
                form: {
                    tab: '',
                    content_type: '',
                    company_logo: '',
                    author: '',
                    header_bgcolor: '',
                    header_textcolor: '',
                    display_header: 0,
                    company_branding: '',
                    title: '',
                    ep_logo: '',
                    ep_logo_small: '',
                    display_cover: 0,
                    headline: '',
                    sub_headline: '',
                    cover_textcolor: '',
                    display_coach: 0,
                    coach_title: '',
                    coach_headline: '',
                    coach_name: '',
                    coach_image: '',
                    coach_bio: '',
                    display_progressbar: 0,
                    progressbar_color: '',
                    display_footer: 0,
                    footer_company: '',
                    footer_bgcolor: '',
                    footer_policy: '',
                    footer_terms: '',
                    footer_textcolor: '',
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_linkedin: '',
                    footer_youtube: '',
                    cta_button: {
                                    display: 0,
                                    url: '',
                                    text: '',
                                    btn_color: '',
                                    position: '',
                                    btn_text_color: '',
                                    option: '',
                                },
                    recipient_ask: {
                                      button_text: '',
                                      link: '',
                                      button_color: '',
                                      button_txt_color: '',
                                      display: 0,
                                      notification: '',
                                      notification_method: 1,
                                      email_recipient: '',
                                      email_subject: '',
                                      email_message: '',
                                      sms_recipient: '',
                                      sms_message: '',
                                  },
                    recipient_understand: {
                                              button_text: '',
                                              link: '',
                                              button_color: '',
                                              button_txt_color: '',
                                              display: 0,
                                              notification: '',
                                              notification_method: 1,
                                              email_recipient: '',
                                              email_subject: '',
                                              email_message: '',
                                              sms_recipient: '',
                                              sms_message: '',
                                          },
                    recipient_complete: {
                                            button_text: '',
                                            link: '',
                                            button_color: '',
                                            button_txt_color: '',
                                            display: 0,
                                            notification: '',
                                            notification_method: 1,
                                            email_recipient: '',
                                            email_subject: '',
                                            email_message: '',
                                            sms_recipient: '',
                                            sms_message: '',
                                        },
                    tag_option: 3,
                    tags: [],
                    selected_tags: [],
                    understand_email_logo: '',
                    understand_show_email_logo: 0,
                    ask_email_logo: '',
                    ask_show_email_logo: 0,
                    complete_email_logo: '',
                    complete_show_email_logo: 0,
                    cover_type: 2,
                    has_small_screen_cover: 0,
                    has_cover_overlay: 0,
                    overlay_color: '#000',
                    overlay_opacity: 5,
                    cover_background_color: '#2F7FED',
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    },
                },
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'default-content',
                },
                recipientAskEmailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                recipientUnderstandEmailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                recipientCompleteEmailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                recipientAskSmsMedia: [],
                recipientUnderstandSmsMedia: [],
                recipientCompleteSmsMedia: [],
                resetToDefaultWatcher: 0,
                uniqueId: uuid.v4(),
                defaultCoachBio: '',
                pageMount: true,
                formMount: true,
                playbookMount: true,
                headerTab: 'logo',
                defaultForm: {},
                alert_prompt: false,
            };
        },

        props: {
            modelValue: Object,
            errors: [Object, Array],
            formType: String,
            tab: String,
            passData: Function,
        },

        emits: ['update:modelValue'],

        components: {
            // Form,
            Field,
            ErrorMessage,
            CoachBioComponent,
            // Swiper,
            // SwiperSlide,
        },

        watch: {
            modelValue: {
                handler (content) {
                    const vm = this;

                    vm.resetForm();
                },
                deep: true,
            },

            tab (content) {
                const vm = this;

                vm.resetForm();
            },

            'form.tab' (tab) {
                if (tab == 'bio') {
                    const vm = this;

                    vm.defaultCoachBio = vm.form.coach_bio;

                    setTimeout(function () {
                        vm.resetToDefaultWatcher += 1;
                    }, 500);

                }
            },

            form () {
                const vm = this;

                vm.passData(vm.form);
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
            fontFamily: state => state.commonModule.fontFamily,
            fontWeight: state => state.commonModule.fontWeight,
            progressDefaultForm: state => state.progressTrackingModule.defaultForm,
            habitDefaultForm: state => state.habitTrackingModule.habitTrackingDefaultSettings,
        }),

        mounted() {
            const vm = this;

            vm.passData(vm.form);
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
            }),

            resetForm () {
                const vm = this;

                if (vm.tab == vm.formType) {
                    const lastTab = vm.form.tab ? vm.form.tab : 'header';
                    const lastActionTab = vm.formType == 'playbook' && vm.form.actionTab ? vm.form.actionTab : 'memberQuestion';

                    vm.form = JSON.parse(JSON.stringify(vm.modelValue));

                    if (!vm.form.headline_setting) {
                        vm.form.headline_setting = {
                            font_family: 'Inter',
                            font_weight: 600,
                            font_size: 50,
                            font_color: '#2C3E50',
                            has_shadow: 0,
                            shadow_x: 0,
                            shadow_y: 4,
                            shadow_blur: 4,
                            shadow_color: 'rgba(0, 0, 0, 0.25)',
                        };
                    }

                    if (!vm.form.subheadline_setting) {
                        vm.form.subheadline_setting = {
                            font_family: 'Inter',
                            font_weight: 600,
                            font_size: 25,
                            font_color: '#2C3E50',
                            has_shadow: 0,
                            shadow_x: 0,
                            shadow_y: 4,
                            shadow_blur: 4,
                            shadow_color: 'rgba(0, 0, 0, 0.68)',
                        };
                    }
                    vm.form.footer_company = vm.form.footer_company ? vm.form.footer_company : `${new Date().getFullYear()} - ${vm.user.businessname ? vm.user.businessname : 'Company Name'}`
                    vm.form.coach_bio = vm.form.coach_bio ? vm.form.coach_bio : 'Joe Trainer has been changing lives since dinosaurs roamed the earth. He is certified to coach clients in almost anything because he is the world\'s most awesome coach. Rumor has it he even taught Joe Biden how to ride a bicycle. Clearly, Joe knows training!';

                    if (vm.form.cta_button && vm.form.cta_button.display) {
                        vm.form.cta_button.display = vm.form.cta_button.display == 'hide' ? 0 : 1;
                    }

                    if (vm.form.selected_tags !== undefined) {
                        vm.form.selected_tags = vm.form.selected_tags ? vm.form.selected_tags : [];
                    }

                    if (vm.formType == 'playbook') {
                        vm.form.headline = vm.form.headline ? vm.form.headline : 'Your Amazing Playbook';
                        vm.form.recipient_ask.notification_method = parseInt(vm.form.recipient_ask.notification_method);
                        vm.form.recipient_ask.display = parseInt(vm.form.recipient_ask.display);
                        vm.form.recipient_understand.notification_method = parseInt(vm.form.recipient_understand.notification_method);
                        vm.form.recipient_complete.notification_method = parseInt(vm.form.recipient_complete.notification_method);

                        vm.recipientAskEmailContent.recipient = vm.form.recipient_ask.email_recipient ? vm.form.recipient_ask.email_recipient : vm.user.email;
                        vm.form.recipient_ask.sms_recipient = vm.form.recipient_ask.sms_recipient ? vm.form.recipient_ask.sms_recipient : vm.user.sms_notification_number;
                        vm.form.recipient_ask.enabled_email_notification = vm.form.recipient_ask.enabled_email_notification == undefined ? 1 : vm.form.recipient_ask.enabled_email_notification;
                        vm.form.recipient_ask.enabled_sms_notification = vm.form.recipient_ask.enabled_sms_notification == undefined ? 1 : vm.form.recipient_ask.enabled_sms_notification;
                        vm.recipientAskEmailContent.subject = vm.form.recipient_ask.email_subject;
                        vm.recipientAskEmailContent.email = vm.form.recipient_ask.email_message;
                        vm.recipientAskEmailContent.is_signature = vm.form.recipient_ask.is_signature;
                        vm.recipientAskEmailContent.signature_id = vm.form.recipient_ask.signature_id;
                        vm.recipientAskEmailContent.show_email_logo = vm.form.ask_show_email_logo;
                        vm.recipientAskEmailContent.email_logo = vm.form.ask_email_logo;
                        vm.recipientAskSmsMedia = vm.form.recipient_ask.media;

                        vm.recipientUnderstandEmailContent.recipient = vm.form.recipient_understand.email_recipient ? vm.form.recipient_understand.email_recipient : vm.user.email;
                        vm.form.recipient_understand.sms_recipient = vm.form.recipient_understand.sms_recipient ? vm.form.recipient_understand.sms_recipient : vm.user.sms_notification_number;
                        vm.form.recipient_understand.enabled_email_notification = vm.form.recipient_understand.enabled_email_notification == undefined ? 1 : vm.form.recipient_understand.enabled_email_notification;
                        vm.form.recipient_understand.enabled_sms_notification = vm.form.recipient_understand.enabled_sms_notification == undefined ? 1 : vm.form.recipient_understand.enabled_sms_notification;
                        vm.recipientUnderstandEmailContent.subject = vm.form.recipient_understand.email_subject;
                        vm.recipientUnderstandEmailContent.email = vm.form.recipient_understand.email_message;
                        vm.recipientUnderstandEmailContent.is_signature = vm.form.recipient_understand.is_signature;
                        vm.recipientUnderstandEmailContent.signature_id = vm.form.recipient_understand.signature_id;
                        vm.recipientUnderstandEmailContent.show_email_logo = vm.form.understand_show_email_logo;
                        vm.recipientUnderstandEmailContent.email_logo = vm.form.understand_email_logo;
                        vm.recipientUnderstandSmsMedia = vm.form.recipient_understand.media;

                        vm.recipientCompleteEmailContent.recipient = vm.form.recipient_complete.email_recipient ? vm.form.recipient_complete.email_recipient : vm.user.email;
                        vm.form.recipient_complete.sms_recipient = vm.form.recipient_complete.sms_recipient ? vm.form.recipient_complete.sms_recipient : vm.user.sms_notification_number;
                        vm.form.recipient_complete.enabled_email_notification = vm.form.recipient_complete.enabled_email_notification == undefined ? 1 : vm.form.recipient_complete.enabled_email_notification;
                        vm.form.recipient_complete.enabled_sms_notification = vm.form.recipient_complete.enabled_sms_notification == undefined ? 1 : vm.form.recipient_complete.enabled_sms_notification;
                        vm.recipientCompleteEmailContent.subject = vm.form.recipient_complete.email_subject;
                        vm.recipientCompleteEmailContent.email = vm.form.recipient_complete.email_message;
                        vm.recipientCompleteEmailContent.is_signature = vm.form.recipient_complete.is_signature;
                        vm.recipientCompleteEmailContent.signature_id = vm.form.recipient_complete.signature_id;
                        vm.recipientCompleteEmailContent.show_email_logo = vm.form.complete_show_email_logo;
                        vm.recipientCompleteEmailContent.email_logo = vm.form.complete_email_logo;
                        vm.recipientCompleteSmsMedia = vm.form.recipient_complete.media;

                        vm.form.actionTab = lastActionTab;
                        vm.form.apply_tag = vm.form.tag_option ? 1 : 0;
                    } else if(vm.formType == 'page'){
                        vm.form.headline = vm.form.headline ? vm.form.headline : 'Your Awesome Page';
                    } else if(vm.formType == 'form'){
                        vm.form.headline = vm.form.headline ? vm.form.headline : 'Your Awesome Form';
                    }


                    vm.form.tab = lastTab;
                    vm.form.logoSetting = 'header';
                    vm.form.coverTab = 'bigScreen';

                    if (!vm.form.content_type) {
                        vm.form.content_type = vm.formType == 'playbook' ? 1 : (vm.formType == 'page') ? 2 : 3;
                    }

                    if (vm.formType == 'progress-tracking') {
                        vm.defaultForm = vm.progressDefaultForm;
                        vm.form.headline = vm.form.headline ? vm.form.headline : 'Time To Submit Your Progress';
                    } else if (vm.formType == 'habit-tracking') {
                        vm.defaultForm = vm.habitDefaultForm;
                        vm.form.headline = vm.form.headline ? vm.form.headline : 'Time To Submit Your Habits';
                    }
                }
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            updateForm () {
                const vm = this;

                vm.$emit('update:modelValue', vm.form);
            },

            handleDefaultEmailRecipientAsk () {
                const vm = this;

                vm.recipientAskEmailContent.subject = 'Playbook Notification!';
                vm.recipientAskEmailContent.email   = `<p>Hi {{company_owner}},</p>
                                                      <p>{{full_name}} asked this question about {{playbook_name}}:</p>
                                                      <p>{{question_message}}</p>
                                                      <p>Click {{question_link}} to answer the question.</p>
                                                      <p>Cheers!</p>
                                                      <p>Thrive Coach</p>`;
            },

            handleDefaultEmailRecipientUnderstand () {
                const vm = this;

                vm.recipientUnderstandEmailContent.subject = 'Playbook Notification!';
                vm.recipientUnderstandEmailContent.email   = `<p>Hi {{company_owner}},</p>
                                                              <p>{{full_name}} has just informed you that they completely understand your {{playbook_name}} playbook.</p>
                                                              <p>You can text or call them now.</p>
                                                              <p>{{phone}}</p>
                                                              <p>Cheers,</p>
                                                              <p>Thrive Coach</p>`;
            },

            handleDefaultEmailRecipientComplete () {
                const vm = this;

                vm.recipientCompleteEmailContent.subject = 'Playbook Notification!';
                vm.recipientCompleteEmailContent.email   = `<p>Hi {{company_owner}},</p>
                                                            <p>{{full_name}} has just completed your {{playbook_name}} playbook.</p>
                                                            <p>Send them a congrats text!</p>
                                                            <p>{{phone}}</p>
                                                            <p>Cheers,</p>
                                                            <p>Thrive Coach</p>`;
            },

            handleDefaultSmsRecipientAsk () {
                const vm = this;

                vm.form.recipient_ask.sms_message = `Hi {{company_owner}},
                                                    \r\n{{full_name}} asked this question about {{playbook_name}}:
                                                    \r\n{{question_message}}
                                                    \r\nClick {{question_link_sms}} to answer the question.
                                                    \r\nCheers,
                                                    \r\nThrive Coach`;
            },

            handleDefaultSmsRecipientUnderstand () {
                const vm = this;

                vm.form.recipient_understand.sms_message = `Hi {{company_owner}},
                                                            \r\n{{full_name}} certifies they understand your {{playbook name}} playbook.
                                                            \r\nCheers,
                                                            \r\nThrive Coach`;
            },

            handleDefaultSmsRecipientComplete () {
                const vm = this;

                vm.form.recipient_complete.sms_message = `Hi {{company_owner}},
                                                          \r\n{{full_name}} certifies they have completed your {{playbook name}} playbook. Send them a congrats and give them a final evaluation.
                                                          \r\nCheers,
                                                          \r\nThrive Coach`;
            },

            setFormData () {
                const vm = this;
                let params = {};

                if (vm.tab != 'progress-tracking' && vm.tab != 'habit-tracking') {
                    const fields = ['company_logo', 'author', 'header_bgcolor', 'header_textcolor', 'display_header',
                                    'company_branding', 'title', 'ep_logo', 'ep_logo_small', 'display_cover', 'headline', 'sub_headline',
                                    'overlay_color', 'cover_textcolor', 'display_coach', 'coach_title', 'coach_headline', 'coach_name',
                                    'coach_image', 'coach_bio', 'display_progressbar', 'progressbar_color', 'display_footer', 'footer_company',
                                    'footer_bgcolor', 'footer_policy', 'footer_terms', 'footer_textcolor', 'footer_facebook', 'footer_twitter',
                                    'footer_instagram', 'cta_button', 'recipient_ask', 'recipient_understand', 'recipient_complete', 'tag_option',
                                    'tags', 'understand_email_logo', 'understand_show_email_logo', 'ask_email_logo', 'ask_show_email_logo',
                                    'complete_email_logo', 'complete_show_email_logo', 'content_type', 'has_footer_facebook', 'has_footer_twitter',
                                    'has_footer_instagram', 'selected_tags', 'headline_setting', 'subheadline_setting', 'cover_type', 'has_small_screen_cover',
                                    'has_cover_overlay', 'overlay_opacity', 'cover_background_color', 'public_settings', 'has_footer_linkedin',
                                    'has_footer_youtube', 'footer_linkedin', 'footer_youtube'
                                  ];

                    if (vm.formType == 'playbook') {
                        vm.form.recipient_ask.email_recipient = vm.recipientAskEmailContent.recipient;
                        vm.form.recipient_ask.email_subject   = vm.recipientAskEmailContent.subject;
                        vm.form.recipient_ask.email_message   = vm.recipientAskEmailContent.email;
                        vm.form.recipient_ask.is_signature    = vm.recipientAskEmailContent.is_signature;
                        vm.form.recipient_ask.signature_id    = vm.recipientAskEmailContent.signature_id;
                        vm.form.recipient_ask.media           = vm.recipientAskSmsMedia;
                        vm.form.ask_show_email_logo           = vm.recipientAskEmailContent.show_email_logo;
                        vm.form.ask_email_logo                = vm.recipientAskEmailContent.email_logo;

                        vm.form.recipient_understand.email_recipient = vm.recipientUnderstandEmailContent.recipient;
                        vm.form.recipient_understand.email_subject   = vm.recipientUnderstandEmailContent.subject;
                        vm.form.recipient_understand.email_message   = vm.recipientUnderstandEmailContent.email;
                        vm.form.recipient_understand.is_signature    = vm.recipientUnderstandEmailContent.is_signature;
                        vm.form.recipient_understand.signature_id    = vm.recipientUnderstandEmailContent.signature_id;
                        vm.form.recipient_understand.media           = vm.recipientUnderstandSmsMedia;
                        vm.form.understand_show_email_logo           = vm.recipientUnderstandEmailContent.show_email_logo;
                        vm.form.understand_email_logo                = vm.recipientUnderstandEmailContent.email_logo;

                        vm.form.recipient_complete.email_recipient = vm.recipientCompleteEmailContent.recipient;
                        vm.form.recipient_complete.email_subject   = vm.recipientCompleteEmailContent.subject;
                        vm.form.recipient_complete.email_message   = vm.recipientCompleteEmailContent.email;
                        vm.form.recipient_complete.is_signature    = vm.recipientCompleteEmailContent.is_signature;
                        vm.form.recipient_complete.signature_id    = vm.recipientCompleteEmailContent.signature_id;
                        vm.form.recipient_complete.media           = vm.recipientCompleteSmsMedia;
                        vm.form.complete_show_email_logo           = vm.recipientCompleteEmailContent.show_email_logo;
                        vm.form.complete_email_logo                = vm.recipientCompleteEmailContent.email_logo;
                    }

                    Object.keys(vm.form).forEach((key) => {
                        if (fields.includes(key)) {
                            params[key] = vm.form[key];
                        }
                    });
                } else if (vm.tab == 'progress-tracking' || vm.tab == 'habit-tracking') {
                    params = vm.form;
                }

                return params;
            },

            handleALertPrompt(){
                const vm = this;
                
                setTimeout(() => {
                    if(vm.form.display_cover || vm.form.public_settings.display_cover){
                        vm.alert_prompt = true;
                    }
                }, 100);
            }
        },
    };
</script>

<style scoped>
    .cover_type {
        margin: 15px -30px 15px -30px;
        padding: 20px 35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
    }
    .cover_type .type_list{
        justify-content: space-around;
    }
    .tab_sec.header{
        overflow-x: overlay;
        position: sticky;
        top: 0;
        background: #f5f5f5;
        z-index: 4;
        padding: 25px 0 1px 0;
        margin: 0 20px 10px 20px;
    }
    .tab_sec::-webkit-scrollbar{
        display: none;
    }
    .tab_sec li{
        flex: 0 1 auto;
        text-align: center;
        min-width: fit-content;
    }
    .topTabs {
        position: sticky;
        top: 0;
        background: #FAFAFB;
        padding-top: 25px;
        z-index: 4;
    }
    .alert_icon{
        max-height: 80px;
        width: auto;
        margin-bottom: 30px;
    }
    @media(max-width: 1199px){
        .tab_sec{
            margin: 0 10px 10px 10px;
        }
    }
    @media(max-width: 499px){
        .tab_sec{
            margin: 0 0 10px 0;
        }
    }
</style>
